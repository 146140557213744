<template>
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Mensajes</h3>
            </div>
            <div class="card-body">
              <!-- Contenido del dashboard -->
              <ul v-if="messages.length">
                <li v-for="message in messages" :key="message.id_mensaje">
                  <strong>Tipo:</strong> {{ message.tipo_mensaje }} <br/>
                  <strong>Texto:</strong> {{ message.texto_mensaje }}
                </li>
              </ul>
              <p v-else>No hay mensajes disponibles.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from '../axios' // Asegúrate de ajustar el camino según tu estructura de proyecto

export default {
  name: 'MessagesPage',
  data() {
    return {
      messages: []
    }
  },
  async created() {
    try {
      const response = await axios.get('/mensaje') // La URL base ya está configurada
      this.messages = response.data
    } catch (error) {
      console.error('Error fetching messages:', error)
    }
  }
}
</script>

<style>
/* Estilos opcionales */
</style>
