import { createStore } from 'vuex'
import axios from 'axios'

const store = createStore({
    state: {
        token: localStorage.getItem('token') || ''
    },
    mutations: {
        setToken(state, token) {
            state.token = token
            localStorage.setItem('token', token)
        },
        clearToken(state) {
            state.token = ''
            localStorage.removeItem('token')
        }
    },
    getters: {
        isAuthenticated: state => !!state.token
    },
    actions: {
        async loginAction({ commit }, { email, password }) {
            try {
                const response = await axios.post('http://77.37.40.97:8080/api/auth/login', { email, password })
                if (response.status === 200 && response.data.token) {
                    commit('setToken', response.data.token)
                    return true
                } else {
                    return false
                }
            } catch (err) {
                console.error('Login error:', err)
                return false
            }
        },
        logoutAction({ commit }) {
            commit('clearToken')
        }
    }
})

export default store
