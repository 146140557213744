<template>
  <div v-if="isAuthenticated">
    <Navbar/>
  </div>
  <div v-if="isAuthenticated">
    <Sidebar/>
  </div>

  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
      </div>
    </div>
    <router-view/>
  </div>
  <div v-if="isAuthenticated">
    <Footer/>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

import Navbar from './components/Navbar.vue'
import Sidebar from './components/Sidebar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Sidebar,
    Footer
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
}
</script>

<style>
/* Estilos opcionales */
</style>
