<template>

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">Login</div>

          <div class="card-body">
            <form @submit.prevent="handleLogin">
              <div class="form-group row">
                <label for="email" class="col-md-4 col-form-label text-md-right">Correo</label>
                <div class="col-md-6">
                  <input id="email" type="email" class="form-control" name="email"
                         v-model="email" required autocomplete="email" autofocus>
                </div>
              </div>

              <div class="form-group row">
                <label for="password" class="col-md-4 col-form-label text-md-right">Contraseña</label>

                <div class="col-md-6">
                  <input id="password" type="password" class="form-control" v-model="password"
                         name="password" required autocomplete="current-password">

                </div>
              </div>

              <div class="form-group row mb-0">
                <div class="col-md-8 offset-md-4">
                  <button type="submit" class="btn btn-primary">
                    Ingresar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <p v-if="error" style="color: red;">{{ error }}</p>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'LoginPage',
  data() {
    return {
      email: 'cperez261@gmail.com',
      password: 'Carlos1993',
      error: null
    }
  },
  methods: {
    ...mapActions(['loginAction']),
    async handleLogin() {
      this.error = null // Reset error before new login attempt
      const success = await this.loginAction({email: this.email, password: this.password})
      if (success) {
        this.$router.push('/Home')
      } else {
        this.error = 'Invalid credentials'
      }
    }
  }
}
</script>
