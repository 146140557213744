<template>
  <footer class="main-footer">
    <div class="float-right d-none d-sm-block">
      <b>Dra. Rossana Ayala Sánchez</b>
    </div>
    R.I.E Odontología
  </footer>
</template>

<script>

export default {
  name: 'Footer',
}
</script>

<style scoped>
/* Estilos opcionales para el navbar */
</style>
