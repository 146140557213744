import axios from 'axios'
import store from './store'

// Crea una instancia de Axios
const instance = axios.create({
    baseURL: 'http://77.37.40.97:8080/api', // Cambia la URL base según tu configuración
    timeout: 1000
})

// Configura un interceptor para agregar el token a cada solicitud
instance.interceptors.request.use(config => {
    // Obtén el token del store
    const token = store.state.token
    if (token) {
        // Agrega el token al encabezado de la solicitud
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
}, error => {
    // Maneja errores en la solicitud
    return Promise.reject(error)
})

export default instance
