<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="#" class="brand-link">
      <span class="brand-text font-weight-light">AdminLTE</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item" v-for="route in routes" :key="route.name">
            <router-link :to="route.path" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ route.name }}
                <i class="right fas fa-angle-left"></i>
              </p>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'Sidebar',
  data() {
    return {
      routes: [
        { name: 'HomePage', path: '/' },
        { name: 'AboutPage', path: '/about' },
        { name: 'MessagesPage', path: '/mensajes' }
      ]
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  setup() {
    const router = useRouter()

    return { router }
  }
}
</script>

<style scoped>
/* Agrega estilos opcionales para el sidebar */
</style>
