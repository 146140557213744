import { createRouter, createWebHistory } from 'vue-router'
import WelcomePage from '../views/WelcomePage.vue'
import HomePage from '../views/HomePage.vue'
import AboutPage from '../views/AboutPage.vue'
import LoginPage from '../views/LoginPage.vue'
import MessagesPage from '../views/MessagesPage.vue'
import store from '../store'

const routes = [
    {
        path: '/',
        name: 'WelcomePage', // Página de bienvenida
        component: WelcomePage
    },
    {
        path: '/home',
        name: 'HomePage',
        component: HomePage,
        meta: { requiresAuth: true }
    },
    {
        path: '/about',
        name: 'AboutPage',
        component: AboutPage,
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/mensajes',
        name: 'MessagesPage',
        component: MessagesPage,
        meta: { requiresAuth: true }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters.isAuthenticated
    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        next({ name: 'LoginPage' })
    } else if (to.name === 'LoginPage' && isAuthenticated) {
        next({ name: 'HomePage' })
    } else {
        next()
    }
})

export default router
