<template>
  <div>
    <h1>Welcome</h1>
    <router-link v-if="!isAuthenticated" to="/login">Login</router-link>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'WelcomePage',
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
}
</script>

<style>
/* Estilos opcionales */
</style>
