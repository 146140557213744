<template>
  <div>
    <h1>Home</h1>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'HomePage',
  methods: {
    ...mapActions(['logoutAction']),
    handleLogout() {
      this.logoutAction()
      this.$router.push('/login')
    }
  }
}
</script>

<style>
nav {
  display: flex;
  gap: 10px;
}
</style>
