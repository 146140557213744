<template>
  <nav class="main-header navbar navbar-expand border-bottom">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#"><i class="fa fa-bars"></i></a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="nav-icon fas fa-cog green"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <a href="#" class="dropdown-item" @click="handleLogout">
            <i class="nav-icon fas fa-power-off red"></i> Cerrar sesión
          </a>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Navbar',
  methods: {
    ...mapActions(['logoutAction']),
    handleLogout() {
      this.logoutAction()
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
/* Estilos opcionales para el navbar */
</style>
