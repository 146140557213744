import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Importar Bootstrap y AdminLTE CSS
import 'admin-lte/dist/css/adminlte.min.css'
import 'admin-lte/dist/js/adminlte.min.js'

// Importa también las dependencias de AdminLTE si no las tienes ya
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

// Crear la aplicación Vue
const app = createApp(App)

// Usar el router y el store
app.use(router)
app.use(store)

// Montar la aplicación
app.mount('#app')
